/* BookingListPresenter */

/* BookingGroupDetail */
/* BookingPrivateDetail */

.am-modal-transparent .am-modal-content .am-modal-body {
    padding: 0;
}

.am-modal-transparent .am-modal-content {
    border-radius: 8px;
    padding-top: 16px;
}

.am-modal-header {
    padding: 0 16px 16px 16px;
}

.modal-style .am-modal-content .am-modal-header .am-modal-title {
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    text-align: left;
    color: #333;
}

.modal-style-detail .am-modal-content .am-modal-header .am-modal-title {
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    color: #333;
}

.modal-style-detail .am-modal-content .am-modal-body {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.71;
    color: #555;
    padding: 0 16px 16px;
}

.modal-style .am-modal-footer .am-modal-button,
.modal-style-detail .am-modal-footer .am-modal-button {
    font-size: 14px;
    font-weight: 500;
}

.modal-style .am-modal-footer .am-modal-button:first-child, .modal-style .am-modal-footer .am-modal-button:last-child {
    color: #555555;
}

.am-modal-mask {
    z-index: 1002;
}

.am-modal-wrap {
    z-index: 1003;
}

/* BoardPresenter */
/* TicketDetail */
.am-badge, .am-tabs-default-bar-tab {
    font-size: 14px;
    /* font-weight: normal; */
}