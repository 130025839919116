.transition-container {
  perspective: 800px;
}

.react-transition {
  animation-fill-mode: both;
}

@keyframes swipeUp {
  from {
    transform: translate3d(0, 100vh, 0);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0vh, 0);
    opacity: 1;
  }
}

.swipe-up {
  animation-timing-function: linear;
  transform-origin: bottom center;
  animation-name: swipeUp;
  animation-duration: 250ms;
}

@keyframes swipeLeft {
  from {
    transform: translate3d(-1000px, 0, 0);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.swipe-left {
  animation-timing-function: ease-in-out;
  transform-origin: bottom center;
  animation-name: swipeLeft;
  animation-duration: 200ms;
}

@keyframes swipeRight {
  from {
    transform: translate3d(1000px, 0, 0);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.swipe-right {
  animation-timing-function: ease-in-out;
  transform-origin: bottom center;
  animation-name: swipeRight;
  animation-duration: 200ms;
}

@keyframes swipeDown {
  from {
    transform: translate3d(0, -100vh, 0);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.swipe-down {
  animation-timing-function: ease-in-out;
  transform-origin: bottom center;
  animation-name: swipeDown;
  animation-duration: 300ms;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fade-in {
  animation-timing-function: ease-in-out;
  transform-origin: bottom center;
  animation-name: fadeIn;
  animation-duration: 250ms;
}

@keyframes scaleIn {
  from {
    transform: translateY(-500px) scale(0.01);
  }

  to {
    transform: scale(1);
  }
}

.scale-in {
  animation-timing-function: ease-in-out;
  transform-origin: bottom center;
  animation-name: scaleIn;
  animation-duration: 250ms;
}

@keyframes scaleInRight {
  from {
    transform: translateY(-500px) translateX(500px) scale(0.01);
  }

  to {
    transform: scale(1);
  }
}

.scale-in-right {
  animation-timing-function: ease-in-out;
  transform-origin: bottom center;
  animation-name: scaleInRight;
  animation-duration: 250ms;
}

@keyframes scaleInLeft {
  from {
    transform: translateY(-500px) translateX(-500px) scale(0.01);
  }

  to {
    transform: scale(1);
  }
}

.scale-in-left {
  animation-timing-function: ease-in-out;
  transform-origin: bottom center;
  animation-name: scaleInLeft;
  animation-duration: 250ms;
}

@keyframes dropIn {
  from {
    transform: scale(4) translateY(400px);
  }

  to {
    transform: scale(1);
  }
}

.drop-in {
  animation-timing-function: ease-in-out;
  transform-origin: bottom center;
  animation-name: dropIn;
  animation-duration: 250ms;
}

@keyframes dropInRight {
  from {
    transform: scale(4) translateY(400px) translateX(400px);
  }

  to {
    transform: scale(1);
  }
}

.drop-in-right {
  animation-timing-function: ease-in-out;
  transform-origin: bottom center;
  animation-name: dropInRight;
  animation-duration: 250ms;
}

@keyframes dropInLeft {
  from {
    transform: scale(4) translateY(400px) translateX(-400px);
  }

  to {
    transform: scale(1);
  }
}

.drop-in-left {
  animation-timing-function: ease-in-out;
  transform-origin: bottom center;
  animation-name: dropInLeft;
  animation-duration: 250ms;
}

@keyframes flipInX {
  from {
    transform: rotateX(90deg);
  }

  70% {
    transform: rotateX(-10deg);
  }

  to {
    transform: rotateX(0deg);
  }
}

.flip-in-x {
  animation-timing-function: linear;
  animation-name: flipInX;
  transform-style: preserve-3d;
  animation-duration: 300ms;
}

@keyframes flipInXReverse {
  from {
    transform: rotateX(-90deg);
  }

  70% {
    transform: rotateX(10deg);
  }

  to {
    transform: rotateX(0deg);
  }
}

.flip-in-x-reverse {
  animation-timing-function: linear;
  animation-name: flipInXReverse;
  transform-style: preserve-3d;
  animation-duration: 300ms;
}

@keyframes flipInY {
  from {
    transform: rotateY(90deg);
  }

  70% {
    transform: rotateY(-10deg);
  }

  to {
    transform: rotateY(0deg);
  }
}

.flip-in-y {
  animation-timing-function: linear;
  animation-name: flipInY;
  animation-duration: 300ms;
  transform-style: preserve-3d;
}

@keyframes flipInYReverse {
  from {
    transform: rotateY(-90deg);
  }

  70% {
    transform: rotateY(10deg);
  }

  to {
    transform: rotateY(0deg);
  }
}

.flip-in-y-reverse {
  animation-timing-function: linear;
  animation-name: flipInYReverse;
  animation-duration: 300ms;
  transform-style: preserve-3d;
}

@keyframes rotateIn {
  from {
    transform: scale(0.1) rotate(180deg);
  }

  70% {
    /*transform:  rotate(10deg);*/
  }

  to {
    transform: rotate(0deg);
  }
}

.rotate-in {
  animation-timing-function: ease-in-out;
  animation-name: rotateIn;
  animation-duration: 300ms;
  transform-style: preserve-3d;
}

@keyframes rotateInReverse {
  from {
    transform: scale(0.1) rotate(-180deg);
  }

  70% {
    /*transform:  rotate(10deg);*/
  }

  to {
    transform: rotate(0deg);
  }
}

.rotate-in-reverse {
  animation-timing-function: ease-in-out;
  animation-name: rotateInReverse;
  animation-duration: 300ms;
  transform-style: preserve-3d;
}

@keyframes woah {
  from {
    transform: scale(0.001) translateX(200px);
  }

  25% {
    transform: scale(0.25) rotateY(180deg) translateX(100px) translateY(100px);
  }

  50% {
    transform: scale(0.5) rotateY(0deg) rotateX(180deg) translateX(-100px) translateY(600px);
  }
}

.woah {
  animation-timing-function: linear;
  animation-name: woah;
  animation-duration: 1s;
  transform-style: preserve-3d;
}

@keyframes blurIn {
  from {
    filter: blur(1.5rem);
    opacity: 1;
  }

  to {
    filter: blur(0);
    opacity: 1;
  }
}

.blur-in {
  animation-timing-function: ease-in-out;
  transform-origin: center;
  animation-name: blurIn;
  animation-duration: 300ms;
}

@keyframes whiteWashIn {
  from {
    filter: brightness(500%);
    opacity: 1;
    background: white;
  }

  to {
    filter: brightness(100%);
    opacity: 1;
    background: rgba(0, 0, 0, 0);
  }
}

.white-wash-in {
  animation-timing-function: linear;
  transform-origin: center;
  animation-name: whiteWashIn;
  animation-duration: 500ms;
}

@keyframes blackWashIn {
  from {
    filter: brightness(0%);
    opacity: 1;
    background: black;
  }

  to {
    filter: brightness(100%);
    opacity: 1;
    background: rgba(0, 0, 0, 0);
  }
}

.black-wash-in {
  animation-timing-function: linear;
  transform-origin: center;
  animation-name: blackWashIn;
  animation-duration: 500ms;
}
