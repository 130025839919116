// $mbsc-frame-background-light: red;
// $mbsc-ios-accent: #e61d2a;
// $mbsc-ios-frame-text: blue;
$mbsc-ios-primary: #3391ED;
$mbsc-ios-secondary: #33bbee;
$mbsc-ios-success: #009988;
$mbsc-ios-danger: #cc3311;
$mbsc-ios-warning: #ee7733;
// $mbsc-ios-info: #64aeff;
$mbsc-ios-info: #555;
$mbsc-ios-light: #ddd;
// $mbsc-material-accent: #e61d2a;
// $mbsc-mobiscroll-accent: #e61d2a;
// $mbsc-ios-frame-accent: red;

@import '@mobiscroll/react/dist/css/mobiscroll.react.scss';
