.react-actionsheet-mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  backface-visibility: hidden;
  background: rgba(0, 0, 0, 0.4);

  transition: opacity 0.3s;
  opacity: 0;
  display: none;
}

.react-actionsheet-show .react-actionsheet-mask {
  opacity: 1;
  display: block;
}

.react-actionsheet-wrap {
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 0px;
  width: 100%;
  transition: all 0.3s;
  z-index: 1001;
  transform: translate3d(0, 101%, 0);

  max-height: calc(100vh - 64px);
  border-radius: 24px 24px 0 0;
  background: white;

  display: grid;
  overflow: hidden;
  grid-template-rows: 1fr auto;

  .close_icon {
    position: absolute;
    right: 0;

    path {
      fill: #555;
    }
  }
}

.react-actionsheet-show .react-actionsheet-wrap {
  transform: translate3d(0, 0, 0);
}